<template>
  <v-card
        class="mx-auto mt-5 pb-3 px-10"
        width="75%"
    >
    <v-card-title>
        <v-row class="my-2">
            <v-col cols="2" class="py-0">
                <v-select
                    class="actions"
                    :class="showSelect"
                    :items="actions"
                    :menu-props="{offsetY: true}"
                    label="Akcia"
                    solo
                    hide-details
                    @change="actionsChanged"
                    ref="actionsEl">
                </v-select>
            </v-col>
            <v-col cols="6" offset="3">
                <h3>
                    Zoznam účtov
                </h3>
            </v-col>
        </v-row>
    </v-card-title>
    <v-card-text>

        <v-data-table
            :loading="loading"
            v-model="selected"
            sort-by="id"
            :headers="headers"
            :items="users"
            :items-per-page="-1"
            item-key="id"
            show-select
            :footer-props="{
                'items-per-page-text': 'Počet riadkov' 
                }"
            @input="itemSelected"
            >

            <template v-slot:item.group="{ item }">
                <v-chip :color="getChipColor(item.group)" dark >
                    {{ getChipText(item.group) }}
                </v-chip>
            </template>

            <template v-slot:item.date_joined="{ item }">
                {{ getJoinedText(item.date_joined) }}
            </template>

            <template v-slot:item.last_login="{ item }">
                {{ getLastLoginText(item.last_login) }}
            </template>

            <template v-slot:item.data-table-select="{ item, isSelected, select }">
                <v-simple-checkbox
                    :ripple="false"
                    :value="item.group === 'admin' ? false : isSelected"
                    :disabled="item.group === 'admin'"
                    @input="select($event)"
                ></v-simple-checkbox>
            </template>
            
        </v-data-table>
    </v-card-text>

    <v-card-actions>
    </v-card-actions>

    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Vymazať účty
        </v-card-title>

        <v-card-text>
            <p></p>
            <div class="text--primary">
                {{ dialog_text }}
            </div>

            <p>
                Táto akcia pernamentne vymaže všetky oznažené účty.
            </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="deleteSelected"
          >
            Vymazať
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>
import axios from 'axios'
import { users } from '@/data/api'

export default {
    name: "ListAccount",

    data () {
      return {
        selected: [],
        headers: [
            { text: 'ID', value: 'id'},
            {
                text: 'Email',
                align: 'start',
                value: 'email',
            },
            { text: 'Uživateľské meno', value: 'display_name' },
            { text: 'Meno', value: 'full_name' },
            { text: 'Rola', value: 'group' },
            { text: 'Vytvorené', value: 'date_joined' },
            { text: 'Posledné prihlásenie', value: 'last_login' },
        ],

        users: [],

        selectedIds: [],

        actions: ['vymazať'],

        loading: false,

        dialog: false,
        dialog_text: ""
    
      }
    },

    computed:{
        showSelect(){
            return this.selectedIds.length > 0 ? '' :  'd-none'
        }
    },

    methods:{
        getChipColor(group){
            if (group === "editor") return "primary"
            else if(group === "admin") return "orange"
            else return "grey"
            
        },
        getChipText(group){
            if(group === "editor") return "Recepcia"
            else if(group === "admin") return "Admin"
            else return "Technik"
        },

        getJoinedText(created){
            let date = new Date(created)
            
            // 31. 12. 2020
            return new Intl.DateTimeFormat('sk-SK').format(date)
        },

        getLastLoginText(last){
            let date = new Date(last)

            return date.toLocaleString("sk-SK")
        },

        itemSelected(items){
            this.selectedIds = []

            for(let i=0; i < items.length; i++){
                if(items[i].group !== "admin"){
                    this.selectedIds.push(items[i].id)
                } 
            }
        },

        actionsChanged(value){
            // clear input value
            this.$refs["actionsEl"].reset()

            if(value === "vymazať"){
                this.dialog = true
                this.dialog_text = "Chýstáte sa vymazať (" + this.selectedIds.length + ") účet/účtov."
            }
        },

        deleteSelected(){
            this.dialog = false

            axios.delete(users(),
            {
                headers: {
                    'Authorization': "Token " + this.$store.getters.token,
                },

                data :{
                    users: this.selectedIds.join(" ")
                }
            }).then(response => {
                if(response.status == 200){
                    // local delete
                    this.users = this.users.filter(item => {
                        return !(this.selectedIds.includes(item.id))
                    })

                    this.selectedIds = []
                    this.selected = []
                }
            })
        }
    },
    activated(){
        this.loading = true
        axios.get(users(),{
            headers: {
                'Authorization': "Token " + this.$store.getters.token,
            }
        }).then(response => {
            this.users = response.data
        }).catch(error =>{
            console.log(error)
        })

        this.loading = false
    }
}
</script>

<style scoped>
.actions{
    min-width: 100px;
    width: 150px;
   
}

</style>