var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto mt-5 pb-3 px-10",attrs:{"width":"75%"}},[_c('v-card-title',[_c('v-row',{staticClass:"my-2"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"2"}},[_c('v-select',{ref:"actionsEl",staticClass:"actions",class:_vm.showSelect,attrs:{"items":_vm.actions,"menu-props":{offsetY: true},"label":"Akcia","solo":"","hide-details":""},on:{"change":_vm.actionsChanged}})],1),_c('v-col',{attrs:{"cols":"6","offset":"3"}},[_c('h3',[_vm._v(" Zoznam účtov ")])])],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"loading":_vm.loading,"sort-by":"id","headers":_vm.headers,"items":_vm.users,"items-per-page":-1,"item-key":"id","show-select":"","footer-props":{
              'items-per-page-text': 'Počet riadkov' 
              }},on:{"input":_vm.itemSelected},scopedSlots:_vm._u([{key:"item.group",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getChipColor(item.group),"dark":""}},[_vm._v(" "+_vm._s(_vm.getChipText(item.group))+" ")])]}},{key:"item.date_joined",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getJoinedText(item.date_joined))+" ")]}},{key:"item.last_login",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getLastLoginText(item.last_login))+" ")]}},{key:"item.data-table-select",fn:function(ref){
              var item = ref.item;
              var isSelected = ref.isSelected;
              var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"ripple":false,"value":item.group === 'admin' ? false : isSelected,"disabled":item.group === 'admin'},on:{"input":function($event){return select($event)}}})]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-card-actions'),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2"},[_vm._v(" Vymazať účty ")]),_c('v-card-text',[_c('p'),_c('div',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(_vm.dialog_text)+" ")]),_c('p',[_vm._v(" Táto akcia pernamentne vymaže všetky oznažené účty. ")])]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.deleteSelected}},[_vm._v(" Vymazať ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }